import { message } from "antd";
import { User } from "../../CONSTANTS/interfaces";
import { CreateWaappUserDto } from "../interfaces/create-waapp-user.interface";
import { getServer } from "../utils/Environment";
const server = getServer();

export async function getUsers() {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "get-users" }),
  });
  return await responce.json();
}

export async function getTradeAgents() {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "get-trade-agents" }),
  });
  return await responce.json();
}

export async function getCompanies() {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "get-companies" }),
  });
  return await responce.json();
}

export async function getPayments(dateFrom, dateTo, companyId) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      dateFrom,
      dateTo,
      companyId,
      method: "get-payments",
    }),
  });
  return await responce.json();
}

export async function getTaPayments(dateFrom, dateTo, companyId) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      dateFrom,
      dateTo,
      companyId,
      method: "get-ta-payments",
    }),
  });
  return await responce.json();
}

export async function getStatisticsByDate(dateFrom, dateTo, user) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({
      dateFrom,
      dateTo,
      user,
      method: "get-statistics-by-date",
    }),
  });
  return await responce.json();
}

export async function removeCompany(id) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ id, method: "remove-company" }),
  });
  return await responce.json();
}

export async function addCompany(companyName, info) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ companyName, info, method: "add-company" }),
  });
  return await responce.json();
}

export async function getDataFromServer(method) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method }),
  });
  return await responce.json();
}
export async function clearAllDevices() {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "clearAllDevices" }),
  });
  return await responce.json();
}

export async function clearUserDevices(id: number, login: string) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "clearUserDevices", id, login }),
  });
  return await responce.json();
}

export async function deleteUserSessionAPI(userId: number, session_id: string) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "delete-user-session", userId, session_id }),
  });
  return await responce.json();
}

export async function createUser(user: CreateWaappUserDto) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "create-user", user }),
  });
  return await responce.json();
}

export async function createTaUser(user: User) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "create-ta-user", user }),
  });
  return await responce.json();
}

export async function editUser(user: User) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "edit-user", user }),
  });
  return await responce.json();
}

export async function editTaUser(user: User) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "edit-ta-user", user }),
  });
  return await responce.json();
}

export async function editUsers(ids: Array<number>, usersData: object) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "multipleEdit", ids, usersData }),
  });
  return await responce.json();
}

export async function login(login: string, password: string) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "login", login, password }),
  });
  return await responce.json();
}

export async function getUserById(id: number) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "get-user-by-id", id }),
  });
  try {
    return await responce.json();
  } catch (error) {
    message.error(error);
    return false;
  }
}

export async function getTaUserById(id: number) {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "get-ta-user-by-id", id }),
  });
  return await responce.json();
}

export const updateUserSetting = async (id: number, setting: object) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "update-user-setting", id, setting }),
  });
  return await responce.json();
};

export const updateUsersSetting = async (
  ids: Array<number>,
  setting: object
) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "update-users-setting", ids, setting }),
  });
  return await responce.json();
};

export const saveWeightTemplate = async (id: number, template: string) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "save-weight-template", id, template }),
  });
  return await responce.json();
};

export const changeLicenseDate = async (ids: Array<number>, date: Date) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "change-license-date", ids, date }),
  });
  return await responce.json();
};

export const editUserFtp = async (values: Array<string>, userId: number) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "update-user-ftp", values, userId }),
  });
  return await responce.json();
};

export const getStatistics = async () => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "get-statistics" }),
  });
  return await responce.json();
};

export const verifyCode = async (code: number, login: string) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "verify-code", code, login }),
  });
  return await responce.json();
};

export const getUserGroups = async (companyName: string) => {
  const responce = await fetch(server + "/server/server.php", {
    method: "POST",
    body: JSON.stringify({ method: "get-user-groups", companyName }),
  });
  return await responce.json();
};
